export const BASE = {
  api: {
    base_url: 'https://service-user-fonte-nova-74hf2txj6q-rj.a.run.app',
    user_validate: '/user/validate/cpf',
    user_document_validate: '/user/validate/document',
    user_create: '/user',
    validate_register_number: '/multiclubes/user/validate',
    send_face: '/biometry',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    dis_proccess: '/biometry/createDIS',
    validate_face: '/biometry/token',
    api_key:
      'a1a137e2469c72e6c166fb622b6a63ad9ff295f90ada6e8ab5c15ba3f61c3712',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  user_api: {
    base_url: 'https://service-user-fonte-nova-74hf2txj6q-rj.a.run.app',
    user_validate: '/user/validate/cpf',
    user_create: '/user',
    validate_register_number: '/multiclubes/user/validate',
    send_face: '/biometry',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    dis_proccess: '/biometry/createDIS',
    validate_face: '/biometry/token',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  biometry_api: {
    base_url: 'https://service-biometry-fonte-nova-74hf2txj6q-rj.a.run.app',
    dis_proccess: '/biometry/createDIS',
    create_face: '/biometry/create',
    validate_face: '/biometry/token',
  }
}
