import React, { useCallback, useEffect, useState } from 'react'
import bepassLogo from './images/bepass_logo.png'
import batfair from './images/betfair_logo.png'
import './App.sass'

import { BASE } from './basedata/constants'

import FaceOnboard from './Biometric/FaceOnboard'
import FaceInstructions from './Biometric/FaceInstructions'
import UserSignup from './User/UserSignup'
import Welcome from './Welcome'
import SuccessHandler from './Biometric/StatesComponents/SuccessHandler'
import { Card } from 'react-bootstrap'

function App() {
  const [userState, setuserState] = useState<string>('')

  const [userToken, setuserToken] = useState<any>('')

  const handleResponse = useCallback((message: any) => {
    setuserState(message.step)
    if (message.token) {
      setuserToken(message.token)
    }
  }, [])

  const verifyBiometry = (token: any) => {


    fetch(`${BASE.biometry_api.base_url}${BASE.biometry_api.validate_face}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        "token": token
      })
    })
      .then((resg) => resg.json())
      .then((response) => {



        if (response.biometry) {

          if (response.callbackUrl) {

            const redirect = window.sessionStorage.setItem('callbackurl', `${response.callbackUrl}`)

          }
          setuserState('facesuccess')


        } else {
          setuserState('faceinformation')
        }


      })
      .catch((error) => {
        return false
      })
  }


  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const generatedToken = window.sessionStorage.getItem('token')
    if (params.get('token')) {
      const currentToken = params.get('token'),
        currentCompany = params.get('orc'),
        iscredentials = params.get('cred')
      setuserToken(params.get('token'))
      window.sessionStorage.setItem('token', currentToken + '')
      window.sessionStorage.setItem('originCompany', currentCompany + '')
      window.sessionStorage.setItem('cred', iscredentials + '')

      return verifyBiometry(currentToken)
      // setuserState('faceinformation')
    }

    else if (generatedToken) {
      const currentToken = generatedToken
      setuserToken(generatedToken)
      return verifyBiometry(generatedToken)
      // setuserState('faceinformation')
    } else {


      setuserState('signup')

    }


  }, [])
  return (
    <>
      <div className="Container">
        <div className="logos">
          <img src="https://storage.googleapis.com/companies-assets/arena-fonte-nova/fonte-nova-new-logo.jpg" className="logo" alt="BePass" />
        </div>

        {userState === 'faceinformation' ? (
          <FaceInstructions onResponse={handleResponse} />
        ) : userState === 'facelogin' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'faceproceed' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'signup' ? (
          <UserSignup onResponse={handleResponse} />
        ) : userState === 'facesuccess' ? (
          <Card>
            <SuccessHandler />
          </Card>
        ) : userState === 'welcome' ? (
          <Welcome onResponse={handleResponse} />
        ) : <></>}
      </div>
      <p className="copyright">
        powered by{' '}
        <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
          <img src={bepassLogo} className="logo-bepass" alt="bepass" />
        </a>
      </p>
    </>
  )
}

export default App
